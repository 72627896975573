import merge from 'lodash/merge';
import { baseConfig } from 'merchants/identance';
import { IConfig } from 'merchants/types/IConfig';
import * as merchant from 'utils/merchant';
import { images } from './images';
import { themeCex } from './theme';
import { i18n } from './locales'

const { backUrl, workingMode, product = '' } = merchant.getSettings();

const customConfig = {
  backUrl,
  workingMode,
  product,
  requestToken: merchant.getToken(),
  features: {
    breadcrumbsInHeader: true,
    breadcrumbs: false,
    exitBtn: true,
    googleTagManagerId: '',
  },
  i18n,
  images,
  theme: {
    name: 'paxful',
    config: themeCex
  },
};

export const config: IConfig  = merge({}, baseConfig, customConfig);
